// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBA3hXtYZksXPcPl6Nk6qhXxukTqj-_KHQ",
  authDomain: "buckeye-sips.firebaseapp.com",
  databaseURL: "https://buckeye-sips-default-rtdb.firebaseio.com",
  projectId: "buckeye-sips",
  storageBucket: "buckeye-sips.appspot.com",
  messagingSenderId: "1029002211275",
  appId: "1:1029002211275:web:d4de957f5728cd43ac504e",
  measurementId: "G-Y8XYFERWXZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app); // Access the database
export const auth = getAuth(app); // Access the authentication service