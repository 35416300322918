import React, { useState } from "react";
import { auth } from "./firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ref, get } from "firebase/database";
import { db } from "./firebase/config";

const Login = ({ setUser }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const collegeList = [
    {
      title: "Ohio State University",
    },
    {
      title: "Ohio University",
    },
  ];
  const [college, setCollege] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCollegeChange = (e) => {
    setCollege(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formData;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const userRef = ref(db, `Colleges/${college}/Bars/${user.uid}`);

        get(userRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              const userData = snapshot.val();
              console.log(userData);
              setUser(userData);
            } else {
              alert(
                "User Data Not Found",
                "No user data found in the database for this user."
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
            alert("Database Error", "There was an error retrieving user data.");
          });
      })
      .catch((error) => {
        switch (error.message) {
          case "Firebase: Error (auth/invalid-credential).":
            alert(
              "Invalid Credentials",
              "Please login using a valid email and password."
            );
            break;
          default:
            alert("Login Error", error.message);
        }
      });
  };

  return (
    <div style={styles.wrapper}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div style={styles.inputWrapper}>
          <label htmlFor="college" style={{ marginRight: 50 }}>
            College:
          </label>
          <select
            id="college"
            value={college}
            onChange={handleCollegeChange}
            required
            style={styles.select}
          >
            <option value="" disabled>
              Select your college
            </option>
            {collegeList.map((college, index) => (
              <option key={index} value={college.title}>
                {college.title}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.inputWrapper}>
          <label htmlFor="email" style={{ marginRight: 50 }}>
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.inputWrapper}>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.logInButton}>
          Login
        </button>
      </form>
      <a href="/register" style={{marginTop: 20}}>Register your bar</a>
    </div>
  );
};

export default Login;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  input: {
    padding: 5,
    fontSize: 16,
    border: "1px solid #ccc",
    width: 250,
  },
  select: {
    padding: 5,
    fontSize: 16,
    border: "1px solid #ccc",
    width: 250,
  },
  logInButton: {
    padding: "5px 10px",
    fontSize: 16,
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    width: "100%",
  },
};
