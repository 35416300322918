import React, { useState, useEffect } from "react";
import { db } from "./firebase/config";
import { ref, set, remove, onValue, push, update } from "firebase/database";
import { useNavigate } from 'react-router-dom';

const Home = ({ user, onLogout }) => {
  const [deals, setDeals] = useState([]);
  const [newDeal, setNewDeal] = useState({
    description: "",
    day: [],
    time: "",
  });
  const [profile, setProfile] = useState({
    instagramUrl: user.instagramUrl || "",
    websiteUrl: user.websiteUrl || "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const dealsRef = ref(db, `Colleges/${user.college}/Bars/${user.uid}/deals`);
    onValue(dealsRef, (snapshot) => {
      const fetchedDeals = snapshot.val();
      if (fetchedDeals) {
        const dealsArray = Object.entries(fetchedDeals).map(([key, value]) => ({
          id: key,
          day: Array.isArray(value.day) ? value.day : [],
          ...value,
        }));
        setDeals(dealsArray);
      } else {
        setDeals([]);
      }
    });
  }, [user]);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const updateProfile = () => {
    const userRef = ref(db, `Colleges/${user.college}/Bars/${user.uid}`);
    update(userRef, {
      instagramUrl: profile.instagramUrl,
      websiteUrl: profile.websiteUrl,
    }).then(() => {
      alert("Profile updated successfully!");
    }).catch((error) => {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    });
  };

  const createDeal = async (newDeal) => {
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const sortedDays = newDeal.day.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b));
  
    const dealsRef = ref(db, `Colleges/${user.college}/Bars/${user.uid}/deals`);
  
    // Generate a new unique key for the deal
    const newDealRef = push(dealsRef);
    const dealKey = newDealRef.key; // Get the unique key generated by push()
  
    const deal = {
      ...newDeal,
      day: sortedDays,
      barName: user.name,
      location: user.location,
    };

    const dealListRef = ref(db, `Colleges/${user.college}/Deals/${dealKey}`);
  
    // Save the deal in both locations using the same key
    await set(newDealRef, deal);
    await set(dealListRef, deal);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (checked) {
        setNewDeal((prevDeal) => ({
          ...prevDeal,
          day: [...prevDeal.day, value],
        }));
      } else {
        setNewDeal((prevDeal) => ({
          ...prevDeal,
          day: prevDeal.day.filter((day) => day !== value),
        }));
      }
    } else {
      setNewDeal({ ...newDeal, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if at least one day is selected
    if (newDeal.day.length === 0) {
      alert("Please select at least one day for the deal.");
      return;
    }

    // Check if description and time fields are not empty
    if (!newDeal.description.trim() || !newDeal.time.trim()) {
      alert("Please fill in both description and time.");
      return;
    }

    // If validation passes, create the deal
    createDeal(newDeal);

    // Clear the form fields
    setNewDeal({ description: "", day: [], time: "" });
  };

  const deleteDeal = async (id) => {
    const dealListRef = ref(db, `Colleges/${user.college}/Deals/${id}`);
    const dealRef = ref(db, `Colleges/${user.college}/Bars/${user.uid}/deals/${id}`);
    await remove(dealRef);
    await remove(dealListRef);
  };

  return (
    <div style={styles.wrapper}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h1>{user.name}</h1>
        <div>
          <button onClick={() => { navigate('/changeEmail'); }}>Change email</button>
          <button onClick={() => { navigate('/changePassword'); }} style={{ marginLeft: 20,}}>Change password</button>
          <button onClick={onLogout} style={{ marginLeft: 20,}} >Logout</button>
        </div>
      </div>
      <hr style={{ border: "none", height: 1, backgroundColor: "black", margin: "20px 0" }} />
      <h3 style={{ marginBottom: 10, marginTop: 0 }}>Your Deals</h3>
      {deals.length > 0 ? (
        deals.map((deal) => (
          <div key={deal.id} style={styles.dealWrapper}>
            <div style={styles.dealText}>
              <span>{deal.description}</span>
              <span>Day(s): {Array.isArray(deal.day) ? deal.day.join(", ") : "N/A"}</span>
              <span>Time: {deal.time}</span>
            </div>
            <button onClick={() => deleteDeal(deal.id)}>Delete</button>
          </div>
        ))
      ) : (
        <p>No deals available. Add a new deal below!</p>
      )}
      <hr style={{ border: "none", height: 1, backgroundColor: "black", margin: "20px 0" }} />
      <h3 style={{ marginBottom: 10, marginTop: 0 }}>Create a New Deal</h3>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="text"
          name="description"
          placeholder="Description"
          value={newDeal.description}
          onChange={handleInputChange}
          style={styles.input}
        />
        <input
          type="text"
          name="time"
          placeholder="Time"
          value={newDeal.time}
          onChange={handleInputChange}
          style={styles.input}
        />
        <div style={styles.checkboxGroup}>
          {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
            <label key={day}>
              <input
                type="checkbox"
                name="day"
                value={day}
                checked={newDeal.day.includes(day)}
                onChange={handleInputChange}
              />
              {day}
            </label>
          ))}
        </div>
        <button type="submit" style={styles.button}>
          Create Deal
        </button>
      </form>
      <hr style={{ border: "none", height: 1, backgroundColor: "black", margin: "20px 0" }} />
      <h3 style={{ marginBottom: 10, marginTop: 0 }}>Profile Information</h3>
      <div style={styles.profileSection}>
          <label>Instagram URL</label>
          <input
            type="text"
            name="instagramUrl"
            placeholder="Instagram URL"
            value={profile.instagramUrl}
            onChange={handleProfileChange}
            style={styles.input}
          />
          <label>Website URL</label>
          <input
            type="text"
            name="websiteUrl"
            placeholder="Website URL"
            value={profile.websiteUrl}
            onChange={handleProfileChange}
            style={styles.input}
          />
          <button onClick={updateProfile} style={styles.button}>
            Update Profile
          </button>
        </div>
    </div>
  );
};

export default Home;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
  },
  dealWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
    margin: "10px 0",
  },
  dealText: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: 10,
    margin: "5px 0",
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: 10,
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    marginTop: 10,
  },
  checkboxGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    margin: "10px 0",
  },
  profileSection: {
    display: "flex",
    flexDirection: "column",
    gap: 10,  // Space between input fields and button
    marginBottom: 20,
  },
};
