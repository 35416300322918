import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import Registration from './Registration';
import { ChangeEmail } from './ChangeEmail';
import { ChangePassword } from './ChangePassword';

function App() {
  const [user, setUser] = useState(() => {
    // Initialize user state from localStorage, if available
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  useEffect(() => {
    // Whenever user state changes, save it to localStorage
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/login'); // Redirect to the login page after logout
  };

  const navigate = useNavigate();

  return (
    <Routes>
      <Route 
        path="/login" 
        element={!user ? <Login setUser={setUser}/> : <Navigate to="/home" replace />} 
      />
      <Route 
        path="/home" 
        element={user ? <Home user={user} onLogout={handleLogout} /> : <Navigate to="/login" />} 
      />
      <Route 
        path="/register" 
        element={!user ? <Registration setUser={setUser}/> : <Navigate to="/home" replace />} 
      />
      <Route 
        path="/changeEmail" 
        element={user ? <ChangeEmail setUser={setUser} user={user}/> : <Navigate to="/login" replace />} 
      />
      <Route 
        path="/changePassword" 
        element={user ? <ChangePassword user={user} /> : <Navigate to="/login" replace />} 
      />
      <Route 
        path="*" 
        element={<Navigate to="/login" />} 
      />
      
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
