import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "./firebase/config";
import { ref, set, onValue } from "firebase/database";

const Registration = ({ setUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [college, setCollege] = useState("");
  const [name, setName] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [location, setLocation] = useState("");
  const colleges = ["Ohio State University", "Ohio University"];

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (college) {
      const locationRef = ref(db, `Colleges/${college}/LocationOptions`);
      onValue(locationRef, (snapshot) => {
        const locations = snapshot.val();
        if (locations) {
          setLocationOptions(Object.values(locations));
        }
      });
    }
  }, [college]);

  const handleRegister = () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (!college || !name) {
      alert("Please select a college and enter your bar name.");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const userModel = {
          uid: user.uid,
          email: user.email,
          college,
          name,
          instagramUrl,
          websiteUrl,
          location,
        };

        const userRef = ref(db, `Colleges/${college}/Bars/${user.uid}`);
        set(userRef, userModel)
          .then(() => {
            alert("Registration successful!");
            setUser(userModel);
            navigate("/home");
          })
          .catch((error) => {
            console.error("Error saving user data:", error);
            alert("Failed to save user data. Please try again.");
          });
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
        switch (error.message) {
          case "Firebase: Error (auth/email-already-in-use).":
            alert("Email already in use. Please login or try another email.");
            break;
          case "Firebase: Error (auth/invalid-email).":
            alert("Invalid email address. Please enter another email.");
            break;
          case "Firebase: Password should be at least 6 characters (auth/weak-password).":
            alert("Weak password. Please use at least 6 characters.");
            break;
          default:
            alert("Registration failed. Please try again.");
        }
      });
  };

  return (
    <div style={styles.wrapper}>
      <h2>Register</h2>
      <input
        type="text"
        placeholder="Bar Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={styles.input}
      />
      <select
          name="college"
          value={college}
          onChange={(e) => setCollege(e.target.value)}
          style={styles.input}
          required
        >
          <option value="" disabled>
            Select your college
          </option>
          {colleges.map((college) => (
            <option key={college} value={college}>
              {college}
            </option>
          ))}
        </select>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={styles.input}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={styles.input}
      />
      <input
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={styles.input}
      />
      <input
        type="text"
        placeholder="Instagram URL"
        value={instagramUrl}
        onChange={(e) => setInstagramUrl(e.target.value)}
        style={styles.input}
      />
      <input
        type="text"
        placeholder="Website URL"
        value={websiteUrl}
        onChange={(e) => setWebsiteUrl(e.target.value)}
        style={styles.input}
      />
      {locationOptions.length > 0 && (
        <select
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          style={styles.select}
        >
          <option value="" disabled>
            Select Location
          </option>
          {locationOptions.map((loc, index) => (
            <option key={index} value={loc}>
              {loc}
            </option>
          ))}
        </select>
      )}
      <button onClick={handleRegister} style={styles.button}>
        Register
      </button>
      <a href="/login" style={{marginTop: 20, alignSelf: 'center'}}>Go back to login</a>
    </div>
  );
};

export default Registration;

const styles = {
  wrapper: {
    maxWidth: 500,
    margin: "auto",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  input: {
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "1px solid #ccc",
  },
  select: {
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "1px solid #ccc",
  },
  button: {
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
    cursor: "pointer",
  },
};
